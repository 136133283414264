<template>
  <v-main>
    <v-container fluid >
      <v-flex xs12 sm6 offset-sm3 mt-13 >
        <v-card
            class="mx-auto"
            outlined
            elevation="5"
        >
          <v-card-text>
            <form class="signup form" @submit.prevent="resetPassword" ref="resetPassword" >
              <v-layout column>
                <v-flex>
                  <v-text-field
                      name="password"
                      label="Пароль"
                      id="id_password"
                      v-model="password"
                      type="password"
                      required></v-text-field>
                </v-flex>
                <v-flex>
                  <v-text-field
                      name="confirmPassword"
                      label="Пароль еще раз"
                      v-model="confirmPassword"
                      id="id_confirmPassword"
                      type="password"
                      required
                  ></v-text-field>
                </v-flex>
                <v-flex class="text-xs-center" mt-5>
                  <v-btn color="primary" type="submit">Сменить пароль</v-btn>
                </v-flex>
              </v-layout>
            </form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-container>
  </v-main>
</template>

<script>
import error from "@/constants/error";
import axios from "axios";

export default {
  data () {
    return {
      password: '',
      confirmPassword: '',
      token: this.$route.params.token
    }
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push("/")
    }
    this.checkToken()
  },
  methods: {
    checkToken() {
      axios.get(process.env.VUE_APP_API_URL + '/api/v1/account/resetPassword/' + this.token + '/')
          .then()
          .catch((err) => {
            if (err.response.status === 401) {
              this.$store.dispatch('logout');
            } else {
              this.$router.push("/error")
            }
          });
    },
    resetPassword: function() {
      let password = this.password;
      let confirmPassword = this.confirmPassword;

      if (password !== confirmPassword) {
        this.$store.dispatch("showSnack", {text: 'Пароли не совпадают', color: "error"})
        return
      }

      let payload = {"password": this.password}
      axios.post(process.env.VUE_APP_API_URL + '/api/v1/account/resetPassword/' + this.token + '/', payload)
          .then(() => {
            this.$store.dispatch("showSnack", {
              text: "Пароль успешно изменен!",
              color: "success"})
            this.$router.push("/")
          })
          .catch((err)=>{
            error.show_error(err.response.data.data)
          });
      this.$refs.resetPassword.reset();
      this.password = ''
      this.confirmPassword = ''
    }
  }
};
</script>

